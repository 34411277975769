let clientId = process && process.env && process.env.REACT_APP_CLIENT_ID ? process.env.REACT_APP_CLIENT_ID : "";
let hostname = window.location.hostname;

switch (hostname) {
  case "test1-eliteslots-lp.play-online.com":
  case "eliteslots-lp-prod.play-online.com":
  case "lp.eliteslots.ro":
    clientId = "1";
    break;

  case "test1-winner-lp.play-online.com":
  case "winner-lp-prod.play-online.com":
  case "lp.winner.ro":
    clientId = "2";
    break;

  case "seven-lp-prod.play-online.com":
  case "lp.seven.ro":
    clientId = "3";
    break;

  case "eldorado-lp-prod.play-online.com":
  case "lp.eldoradocasino.ro":
    clientId = "9";
    break;

  case "excelbet-lp-prod.play-online.com":
  case "lp.excelbet.ro":
    clientId = "10";
    break;

  default:
  /* noop */
}

export { clientId };
