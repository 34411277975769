import { clientId } from "../init";

const stagingEndpoints = {
  betsApiUrl: "https://test1-micros-1.play-online.com",
  configApiUrl: `https://test1-micros-1.play-online.com/landingpagesapi/config/${clientId}`,
};

const productionEndpoints = {
  betsApiUrl: "https://micros1-ro.play-online.com",
  configApiUrl: `https://micros1-ro.play-online.com/landingpagesapi/config/${clientId}`,
};

let endpoints;

endpoints = stagingEndpoints;

let hostname = window.location.hostname;
let isProduction = false;

switch (hostname) {
  case "eliteslots-lp-prod.play-online.com":
  case "seven-lp-prod.play-online.com":
  case "excelbet-lp-prod.play-online.com":
  case "eldorado-lp-prod.play-online.com":
  case "winner-lp-prod.play-online.com":
  case "pr-lp.winner.ro":
  case "lp.winner.ro":
    isProduction = true;
    break;

  default:
  /* noop */
}

if (isProduction) {
  endpoints = productionEndpoints;
}

endpoints = productionEndpoints;

export default endpoints;