import React from 'react';
import { clientId } from "../init";

const Home = (props) => {
  React.useEffect(() => {
    if (clientId === "1") {
      window.location.assign("https://www.eliteslots.ro/");
    } else if (clientId === "2") {
      window.location.assign("https://www.winner.ro/");
    } else if (clientId === "3") {
      window.location.assign(" https://www.seven.ro/");
    } else if (clientId === "9") {
      window.location.assign("https://www.eldoradocasino.ro/");
    } else if (clientId === "10") {
      window.location.assign("https://online.excelbet.ro/");
    }
  });

  return (
    <div>Redirecting ...</div>
  );
}

export default Home;